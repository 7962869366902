import request from '@/lib/request'

export async function getList(query) {
  return request({
    url: '/api/client/article',
    params: query
  })
}

export async function getOne(id) {
  return request({
    url: '/api/client/article/' + id
  })
}
