<template>
  <div class="page content-wrap">
    <div v-if="query.search" class="p-title">
      所有包含 "<span class="search">{{ query.search }}</span>" 的结果
    </div>
    <div id="article-doc" class="articles ">
      <div
        v-for="item in articles"
        :key="item._id"
        class="a-sm article"
        @click="handlerGetDetail(item)"
      >
        <div class="image">
          <el-image fit="cover" :src="item.image_url">
            <div
              slot="error"
              class="image-slot"
              style="display:flex;width:100%;height:100%;justify-content: center;align-items: center;"
            >
              <i class="el-icon-picture-outline" />
            </div>
          </el-image>
        </div>
        <div class="content">
          <div class="content-title">
            {{ item.title }}
          </div>
          <div class="content-digest">
            {{ item.digest }}
          </div>
        </div>
      </div>

    </div>
    <div class="page-footer">
      <el-button
        v-if="total > articles.length"
        :loading="loading"
        type=""
        @click="addMore"
      >加载更多</el-button>
    </div>

  </div>
</template>
<style lang="scss" scoped>
.p-title {
  padding: 20px;
  color: #333333;
  .search {
        color: #F56C6C
  }
}
.page-footer {
  text-align: center;
  margin: 20px;
}
.articles {
  background-color: #ffffff;
  border-radius: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .title {
    font-weight: bold;
    line-height: 1.5em;
    font-size: 22px;
    padding: 10px 20px;
  }
  .article {
    padding: 10px;
    cursor: pointer;
    width: 45%;
    border-bottom: #dcdfe6 1px solid;
    .content {
      flex: 1;
      .content-title {
        font-size: 20px;
        line-height: 1.5em;
      }
      .content-digest {
        font-size: 14px;
        line-height: 1.5em;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all;
        word-wrap: break-word;
      }
    }
  }
  .a-sm {
    display: flex;
    flex-direction: row-reverse;
    .el-image {
      height: 120px;
      width: 120px;
      padding: 0 10px;
    }
  }
  .el-divider--horizontal {
    margin: 10px 0;
  }
}

@media screen and (max-width: 992px) {
  .article{
    width: 96% !important;
  }
}

</style>

<script>
import { getList } from '@/api/article'
export default {
  data() {
    return {
      query: {
        page: 1,
        pageSize: 20,
        categroy: '',
        search: ''
      },
      articles: [],
      total: 0,
      loading: false
    }
  },
  mounted() {
    if (this.$route.name === 'ArticleSearch') {
      this.query.search = this.$route.params.query
      this.query.pageSize = 100
    } else if (this.$route.name === 'ArtilcleList') {
      this.query.categroy = this.$route.params.type
    }
    this.handlerGetList()
  },
  methods: {
    handlerGetList() {
      getList(this.query).then(res => {
        // console.log(res)
        this.articles = [...this.articles, ...res.list]
        this.total = res.page.total
        this.loading = false
        if (this.query.search) {
          this.$nextTick(() => {
            // this.handlerReflash()
          })
        }
      })
    },
    handlerGetDetail(row) {
      // this.$router.push('/article/' + row._id)
      if (row.wx_url) {
        window.open(row.wx_url)
      } else {
        this.$router.push('/article/' + row._id)
      }
    },
    addMore() {
      this.loading = true
      this.query.page++
      this.handlerGetList()
    },
    handlerReflash() {
      var oBox = document.getElementById('article-doc')
      var oContent = oBox.innerHTML
      // var testTxt = document.getElementById('testTxt')
      var val = this.query.search
      var findText = oContent.split(val)
      oBox.innerHTML = findText.join('<span style="color:#F56C6C;">' + val + '</span>')
    }
  }
}
</script>
